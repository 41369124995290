import * as React from 'react'
import styled from 'styled-components'
import contactBanner from '../assets/contact-banner.jpg'
import email from '../assets/email-icon.png'
import location from '../assets/location-icon.png'
import map from '../assets/map.png'
import * as C from '../components'
import * as S from '../styles'

const Container = styled.div`
  background: white;
  padding: 45px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  @media (max-width: 767px) {
    padding: 30px;
  }

  ${S.Heading} {
    font-size: 20px;
  }

  ${S.Button} {
    width: 100%;
    margin-top: 15px;
  }
`

const Item = styled.div`
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

const MapContainer = styled(S.GridItem)`
  @media (max-width: 1023px) {
    display: none;
  }
`

const Map = styled.img`
  margin: -80px auto 0;
`

const Contact: React.SFC = () => {
  return (
    <C.App>
      <S.Section
        className="intro product-intro"
        style={{
          background: `url(${contactBanner}) center / cover no-repeat`,
        }}>
        <S.Wrapper className="small">
          <S.Heading className="large white">Contact Us</S.Heading>
        </S.Wrapper>
      </S.Section>
      <S.Section className="sub">
        <S.Wrapper>
          <S.Grid>
            <S.GridItem
              width="40%"
              breakpoints={{
                1024: '60%',
                768: '100%',
              }}>
              <Container>
                <Item>
                  <S.Grid>
                    <S.GridItem width="40px">
                      <img src={email} />
                    </S.GridItem>
                    <S.GridItem width="calc(100% - 60px)">
                      <S.Heading className="smedium">Email</S.Heading>
                      <S.Text className="medium">
                        <a href="mailto:bonjour@lieuditwinery.com">
                          bonjour@lieuditwinery.com
                        </a>
                      </S.Text>
                    </S.GridItem>
                  </S.Grid>
                </Item>
                <Item>
                  <S.Grid>
                    <S.GridItem width="40px">
                      <img src={location} />
                    </S.GridItem>
                    <S.GridItem width="calc(100% - 60px)">
                      <S.Heading className="smedium">
                        <a
                          href="https://www.exploretock.com/tyler-x-lieu-dit"
                          target="_blank">
                          New! Visit our Tasting Room
                        </a>
                      </S.Heading>
                      <S.Text className="medium">
                        23 E Canon Perdido St
                        <br />
                        Santa Barbara, CA 93101
                        <br />
                        <a
                          href="https://www.exploretock.com/tyler-x-lieu-dit"
                          target="_blank">
                          Book Now
                        </a>
                      </S.Text>
                    </S.GridItem>
                  </S.Grid>
                </Item>
                <Item>
                  <div
                    id="Tock_widget_container"
                    data-tock-display-mode="Button"
                    data-tock-color-mode="Blue"
                    data-tock-locale="en-us"
                    data-tock-timezone="America/Los_Angeles"></div>
                </Item>
                <Item>
                  <S.Grid>
                    <S.GridItem width="40px">
                      <img src={location} />
                    </S.GridItem>
                    <S.GridItem width="calc(100% - 60px)">
                      <S.Heading className="smedium">Winery Address</S.Heading>
                      <S.Text className="medium">
                        300 North 12th Street, Unit 4A
                        <br />
                        Lompoc, CA 93436
                      </S.Text>
                    </S.GridItem>
                  </S.Grid>
                </Item>
              </Container>
            </S.GridItem>
            <MapContainer width="48%">
              <Map src={map} />
            </MapContainer>
          </S.Grid>
        </S.Wrapper>
      </S.Section>
      <C.JoinCommunity />
    </C.App>
  )
}

export default Contact
